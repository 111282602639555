exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-connect-jsx": () => import("./../../../src/pages/connect.jsx" /* webpackChunkName: "component---src-pages-connect-jsx" */),
  "component---src-pages-event-space-jsx": () => import("./../../../src/pages/event-space.jsx" /* webpackChunkName: "component---src-pages-event-space-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-studios-jsx": () => import("./../../../src/pages/media-studios.jsx" /* webpackChunkName: "component---src-pages-media-studios-jsx" */),
  "component---src-pages-meeting-rooms-jsx": () => import("./../../../src/pages/meeting-rooms.jsx" /* webpackChunkName: "component---src-pages-meeting-rooms-jsx" */),
  "component---src-pages-workspaces-jsx": () => import("./../../../src/pages/workspaces.jsx" /* webpackChunkName: "component---src-pages-workspaces-jsx" */),
  "component---src-templates-custom-page-jsx": () => import("./../../../src/templates/custom-page.jsx" /* webpackChunkName: "component---src-templates-custom-page-jsx" */),
  "component---src-templates-event-page-jsx": () => import("./../../../src/templates/event-page.jsx" /* webpackChunkName: "component---src-templates-event-page-jsx" */),
  "component---src-templates-office-space-page-jsx": () => import("./../../../src/templates/office-space-page.jsx" /* webpackChunkName: "component---src-templates-office-space-page-jsx" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */),
  "slice---src-components-modals-jsx": () => import("./../../../src/components/Modals.jsx" /* webpackChunkName: "slice---src-components-modals-jsx" */)
}

